<template>
  <div class="h-100 w-100 d-flex align-items-center justify-content-center p-4">
    <div class="text-center">
      <div class="text-center mb-0">
        <img class="mb-3" style="width: 100px;height: 100px;" src="/img/icons/android-chrome-192x192.png">
        <h1 class="mb-0">Wprowadź PIN</h1>
      </div>
      <Pinpad @input="loginQrcode"></Pinpad>
      <!-- <button @click="checkPin">DEV: Ustaw kierowce nr 3</button> -->
    </div>
  </div>
</template>
<script>
import Pinpad from "../components/Pinpad.vue";
import api from "../api/myApi";

export default {
  name: "StartPinView",
  components: { Pinpad },
  props: {
    msg: String,
  },
  computed: {},
  methods: {
    loginQrcode: function (result) {
      if (result == '999999999') {
        this.checkPin();
        return;
      }
      api.getKierowcaByPin(result).then(
        (response) => {
          this.$store.commit("setUserId", response.data.user_id);
          this.$router.push("/");
        },
        (error) => {
          if (error.response && error.response.status == 422) {
            return;
          }
          this.pin = "";
        }
      );
    },

    checkPin() {
      this.$store.commit("setUserId", 3);
      this.$router.push("/");
    },
  },
  mounted() {
    // this.$store.dispatch('getKartaDrogowa')
    if (this.$store.state.user_id) {
      this.$router.push('/')
    }
  },
};
</script>

<style>

</style>
