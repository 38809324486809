<template>
    <div class="d-inline-block">
        <CameraScanner @input="submitCode"></CameraScanner>
        <KeyboardScanner @input="submitCode" :listen="true"></KeyboardScanner>
        <div v-if="loading" class="loader-center text-center">
            <div class="spinner-grow" role="status">
            </div>
        </div>
    </div>
</template>
<script>

import KeyboardScanner from '../../components/KeyboardScanner.vue'
import CameraScanner from '../../components/CameraScanner.vue'

export default {
    name: 'StoreCylinderScanner',
    components: {
        CameraScanner,
        KeyboardScanner,
    },
    data: function () {
        return {
            loading: false,
        }
    },
    methods: {
        submitCode: function (code) {
            this.$emit('change');
            this.loading = true;
            let vm = this;
            this.$store.dispatch('storage/findCylinder', code).then(cylinder => {
                let cylinder_old = vm.$store.getters['production/getCylinder'](cylinder.cylinder_id);
                if (cylinder_old) {
                    vm.$toast.warning("Butla już zeskanowana")
                    new Audio('/beep3.mp3').play();
                    window.navigator?.vibrate?.([100, 50, 200, 50]);
                } else {
                    if (cylinder.isComplaint || cylinder.isLegalization==false) {
                        new Audio('/beep3.mp3').play();
                        window.navigator?.vibrate?.([100, 50, 200, 50, 100, 50]);

                    } else {
                        new Audio('/beep.mp3').play();
                        window.navigator?.vibrate?.(200);

                    }

                    vm.$store.commit('production/cylinderAdd', cylinder);
                    let product = vm.$store.getters['production/getProduct'](cylinder.product_id);
                    if (!product) {
                        let product = vm.$store.getters['storage/getProductFromDictionary'](cylinder.product_id);
                        if (product) {
                            this.$store.commit('production/addProduct', product);
                        }
                    }

                    // let cylinder_fit = vm.$store.getters['pickup/getProduct'](cylinder.product_id);
                    // if (!cylinder_fit) {
                    //     vm.$toast.warning("Butla " + code + " nie pasuje do zamówień")
                    // } else {
                    vm.$toast.success("Zeskanowano pomyślnie nr " + code);
                    //}
                    this.$store.dispatch('production/validate');
                }
                vm.loading = false;
            }, error => {
                let lastEnd = vm.$store.getters['storage/getCylinderDbQrcode'](code)
                console.log('nie ma intenretu')
                if (lastEnd) {
                    vm.$toast.success("Zeskanowano pomyślnie nr " + code);
                    vm.$store.commit('production/cylinderAdd', lastEnd);
                    let product = vm.$store.getters['production/getProduct'](lastEnd.product_id);
                    if (!product) {
                        let product = vm.$store.getters['storage/getProductFromDictionary'](lastEnd.product_id);
                        if (product) {
                            this.$store.commit('production/addProduct', product);
                        }
                    }
                    this.$store.dispatch('production/validate');
                } else {
                    console.log(error);
                    vm.$toast.error("Nie znaleziono butli " + code);
                    new Audio('/beepw.mp3').play();

                }
                vm.loading = false;
            })
        }
    }
}
</script>