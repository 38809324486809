import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import StartKarta from '../views/StartKarta.vue'
import StartPin from '../views/StartPin.vue'

import Login from '../views/Login.vue'
import Loading from '../views/loading/Loading.vue'
import LoadingItem from '../views/loading/LoadingItem.vue'
import LoadingLocation from '../views/loading/LoadingLocation.vue'
import LoadingBadCylinder from '../views/loading/LoadingBadCylinder.vue'
import LoadingSelectProduct from '../views/loading/SelectProduct.vue'


import Settings from '../views/Settings.vue'
import Storage from '../views/storage/Storage.vue'
import StorageProduct from '../views/storage/StorageProduct.vue'
import Orders from '../views/Orders.vue'
import ComplaintCreate from '../views/complaint/ComplaintCreate.vue';
import ComplaintSuccess from '../views/complaint/ComplaintSuccess.vue';
import ComplaintSearch from '../views/complaint/ComplaintSearch.vue';

import CylinderSearch from '../views/cylinder/CylinderSearch.vue'
import CylinderShow from '../views/cylinder/CylinderShow.vue'

import Summary from "../views/summary/Summary.vue"
import SummaryNoInternet from "../views/summary/SummaryNoInternet.vue"

import SummaryEnd from "../views/SummaryEnd.vue"
import Disloading from '../views/disloading/Disloading.vue'

import DisloadingQuestionStart from '../views/disloading/QuestionStart.vue'
import DisloadingQuestionEnd from '../views/disloading/QuestionEnd.vue'

import DisloadingProduct from "../views/disloading/DisloadingProduct.vue"
import DisloadingBadCylinder from "../views/disloading/DisloadingBadCylinder.vue"

import SelectOrder from "../views/disloading/SelectOrder.vue"

import Pickup from '../views/pickup/Pickup.vue'
import PickupSelectProduct from '../views/pickup/SelectProduct.vue'
import PickupProduct from "../views/pickup/PickupProduct.vue"
import PickupBadCylinder from "../views/pickup/PickupBadCylinder.vue"
import PickupQuestionStart from '../views/pickup/QuestionStart.vue'

import PickupSelectLocation from "../views/pickup/SelectLocation.vue"
import PickupSummary from "../views/pickup/Summary.vue"


import Production from '../views/production/Production.vue'
import ProductionSelectProduct from '../views/production/SelectProduct.vue'
import ProductionProduct from "../views/production/ProductionProduct.vue"

import Menu from '../views/Menu.vue';

Vue.use(VueRouter)
const routes = [
    {
        path: '/menu',
        name: '',
        component: Menu
    },
    {
        path: '/login',
        name: '',
        component: Login
    }, {
        path: '/start/karta',
        name: '',
        component: StartKarta
    }, {
        path: '/start/pin',
        name: '',
        component: StartPin
    }, {
        path: '/',
        name: '',
        component: Home
    },
    {
        path: '/updated',
        name: 'updated',
        component: Home
    }, {
        path: '/settings',
        name: 'settings',
        component: Settings
    }, {
        path: '/loading',
        name: 'loading',
        component: Loading
    }, {
        path: '/loading/location',
        name: 'loadinglocation',
        component: LoadingLocation
    }, {
        path: '/loading/badcylinder',
        name: 'loadingbadcylinder',
        component: LoadingBadCylinder
    }, {
        path: '/loading/selectproduct',
        name: 'loadingselectproduct',
        component: LoadingSelectProduct
    }, {
        path: '/loading/item/:id',
        name: 'loadingitem',
        component: LoadingItem
    },
    {
        path: '/summary',
        name: 'disloading',
        component: Summary
    },
    {
        path: '/summary/nointernet',
        name: 'SummaryNoInternet',
        component: SummaryNoInternet
    },
    {
        path: '/summary/end',
        name: 'disloading',
        component: SummaryEnd
    },
    {
        path: '/disloading/selectorder',
        name: 'disloading',
        component: SelectOrder,
        props: {
            next: '/disloading/form',
            type: 'order'
        }
    },


    {
        path: '/disloading',
        name: 'disloading',
        component: Disloading
    },
    {
        path: '/disloading/start/:id',
        name: 'disloadingstart',
        component: DisloadingQuestionStart
    },
    {
        path: '/disloading/product/:id',
        name: 'disloadingprdocut',
        component: DisloadingProduct
    },
    {
        path: '/disloading/badcylinder',
        name: 'disloadingbadcylinder',
        component: DisloadingBadCylinder
    },
    {
        path: '/disloading/end',
        name: 'disloadingend',
        component: DisloadingQuestionEnd
    },
    {
        path: '/pickup',
        name: 'pickup',
        component: Pickup
    },
    {
        path: '/pickup/start/:id',
        name: 'pickupstart',
        component: PickupQuestionStart
    },
    {
        path: '/pickup/selectlocation',
        name: 'pickup',
        component: PickupSelectLocation,

    },
    {
        path: '/pickup/product/:id',
        name: 'pickupprdocut',
        component: PickupProduct
    },

    {
        path: '/pickup/summary',
        name: 'pickupsummary',
        component: PickupSummary
    },
    {
        path: '/pickup/badcylinder',
        name: 'pickupbadcylinder',
        component: PickupBadCylinder
    },
    {
        path: '/pickup/selectproduct',
        name: 'pickupbadselectproduct',
        component: PickupSelectProduct
    },


    {
        path: '/production',
        name: 'pickup',
        component: Production
    },
    {
        path: '/production/product/:id',
        name: 'Productionprdocut',
        component: ProductionProduct
    },

    {
        path: '/production/selectproduct',
        name: 'Productionbadselectproduct',
        component: ProductionSelectProduct
    },


    {
        path: '/storage',
        name: 'storage',
        component: Storage
    },
    {
        path: '/storage/product/:id',
        name: 'storageproduct',
        component: StorageProduct
    }, {
        path: '/orders',
        name: 'orders',
        component: Orders
    },
    {
        path: '/cylinder/search',
        name: 'cylindersearch',
        component: CylinderSearch
    },
    {
        path: '/cylinder/qrcode/:code',
        name: 'cylindershow',
        component: CylinderShow
    },
    {
        path: '/complaint/create',
        name: 'ComplaintCreateCylidner',
        component: ComplaintCreate
    },
    {
        path: '/complaint/search',
        name: 'ComplaintSearch',
        component: ComplaintSearch
    },
    {
        path: '/complaint/:id',
        name: 'ComplaintCreateCylidner',
        component: ComplaintSuccess
    },

]
const router = new VueRouter({
    mode: "history",
    routes
})
export default router