export default {
    errorMsgCylinderLoading: {
        'empty': 'Butla pusta',
        'locationFail': 'Butla nie jest w tym magazynie',
        'orderFail': 'Butla nie pasuje do zamówienia',
        'badCylinderProduct': 'Butla nie pasuje do żadnego produktu',
        'full': 'Butla jest pełna',
        'complaint': 'Butla posiada reklamacje',
        'legalization':'Butla musi przejść legalizacje'
    },
    errorMsgCylinderDisloading: {
        'empty': 'Butla pusta',
        'locationFail': 'Butla nie jest na aucie',
        'orderFail': 'Butla nie pasuje do zamówienia',
        'badCylinderProduct': 'Butla nie pasuje do żadnego produktu',
        'full': 'Butla jest pełna',
        'complaint': 'Butla posiada reklamacje',
        'legalization':'Butla musi przejść legalizacje'
    },
    errorMsgCylinderPickup: {
        'empty': 'Butla pusta',
        'locationFail': 'Butla nie jest na tym aucie',
        'orderFail': 'Butla nie pasuje do zamówienia',
        'badCylinderProduct': 'Butla nie pasuje do żadnego produktu',
        'full': 'Butla jest pełna',
        'complaint': 'Butla posiada reklamacje',
        'legalization':'Butla musi przejść legalizacje'
    }

}