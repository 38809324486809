<template>
    <div v-if="location"
        class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative">

        <div class="w-100 d-flex justify-content-between align-items-center" style="background: #dadada">
            <router-link to="/" class="btn btn-lg btn-secondary m-2"><i class="bi bi-chevron-left"></i></router-link>
            <StoreCylinderScanner @change="showError=true"></StoreCylinderScanner>
            <button @click="cancelPickup" class="btn btn-lg btn-secondary m-2"><i class="bi bi-x-octagon"></i></button>
            <!-- <button @click="cancelPickup" class="btn btn-lg btn-secondary m-2"><i class="bi bi-x-octagon"></i></button> -->
            <router-link v-if="$store.state.pickup.valid || !showError" type="button" to="/pickup/summary"
                class="btn btn-lg btn-success fs-5 flex-fill m-2">Dalej</router-link>
            <ValidationErrorsModal v-if="!$store.state.pickup.valid && showError" type="button"></ValidationErrorsModal>
        </div>
        <div class="bg-light p-2 d-flex justify-content-between mb-2 w-100">
            <span class="col-9 me-2">Odbiór od:<br> {{ location.location_name }}</span>
        </div>

        <div class="w-100 p-2 pb-5 flex-grow-1" style="overflow: auto;">
            <div class="text-center" v-if="items.length == 0">
                Dodaj produkt lub zeskanuj butlę.
            </div>
            <router-link :to="'/pickup/product/' + item.product_id" v-for="item, key in items" :key="key"
                :class="$store.getters['pickup/isLastScannedProduct'](item.product_id) ? 'bg-lightgreen' : 'bg-light'"
                class="p-2 text-dark d-flex justify-content-between mb-2 align-items-center">
                <span class="flex-fill pe-2">{{ item.name }} </span>

                <span
                    :class="{ 'bg-danger': $store.getters['pickup/getErrors']({ product_id: item.product_id }).length > 0, 'bg-warning': $store.getters['pickup/getWarnings']({ product_id: item.product_id }).length > 0 }"
                    class=" bg-secondary col-3 badge fs-5 d-flex align-items-center justify-content-center">{{
                        $store.getters['pickup/countCylinder'](item.product_id)
                    }} / {{ $store.getters['pickup/countInLocation'](item.product_id) }} </span>
            </router-link>

            <router-link v-if="badCylinders.length > 0" :to="'/pickup/badcylinder'"
                class="bg-light p-2 text-dark d-flex justify-content-between mb-2 align-items-center">
                <span class="flex-fill pe-2 text-danger">Butle nie pasujące</span>

                <span class=" bg-danger col-3 badge fs-5 d-flex align-items-center justify-content-center">
                    {{ badCylinders.length }}</span>
            </router-link>

        </div>
        <!-- Magazyn żródłowy: {{ locationFrom.location_name }} -->
        <router-link to="/pickup/selectproduct"
            style="width: 50px; height: 50px; background: #dadada; border-radius: 5px; font-weight: bold; position: fixed; right: 10px; bottom: 10px;z-index:1000"
            class="d-flex justify-content-center align-items-center fs-1 text-dark">
            <i class="bi bi-plus"></i>
        </router-link>
        <!-- {{ $store.state.zaladunek.cylinders.length }}/ {{ iloscBulti }}< -->
    </div>
</template>
<script>
// import myApi from '@/api/myApi'

import StoreCylinderScanner from '@/components/pickup/StoreCylinderScanner.vue';
// import api from '@/api/myApi';
import ValidationErrorsModal from '@/components/pickup/ValidationErrorsModal.vue';
// import CylinderListElement from '../components/CylinderListElement.vue'

export default {
    name: 'PikcupView',
    components: {
        StoreCylinderScanner,
        ValidationErrorsModal
    },
    props: {
        msg: String,
    },
    data: function () {
        return {
            submitCode: '',
            location_id: 0,
            orders: [],
            iloscBulti: 0,
            iloscBultiinOrders: 0,
            skantext: '',
            loadTowary: [],
            cylinders: [], //zeskanowowane butle
            inne: [], //produkty ktore sa złe
            pojazd_id: 0,
            listaQrcodowDoOPrzyjecia: [],
            showError: false,

        }
    },
    computed: {
        location: function () {
            return this.$store.state.pickup.locationFrom;
        },
        items: function () {
            return this.$store.state.pickup.products;
        },
        // locationFrom: function () {
        //     return this.$store.state.zaladunek.locationFrom ? this.$store.state.zaladunek.locationFrom : {};
        // },
        badCylinders: function () {
            return this.$store.getters["pickup/getBadCylinder"];
        },
    },
    methods: {


        cancelPickup: function () {

            this.$swal({
                title: 'Chcesz przerwać proces odbioru?',
                text: "Ta akcja wyczyści wszystko co zeskanowałeś do tej pory! Jest to nieodrwacalne",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Wróć',
                confirmButtonText: 'Tak, przerywam!'
            }).then(response => {
                if (response.isConfirmed) {
                    this.$store.dispatch('pickup/reset');
                    this.$router.push('/');
                }
            })
            //
        },

    },
    created() {
        // if (this.$store.state.disloading.order && !this.$store.state.pickup.order) {
        //     this.$swal('Musisz najpierw zakończyć proces wydawania towaru aby przyjąć towar od klienta!').then(() => {
        //         this.$router.push('/');
        //     });
        // }
        // if (this.$store.state.zaladunek.locationFrom) {
        //     this.$swal('Musisz najpierw zakończyć proces ładowania towaru aby wydać towar do klienta!').then(() => {
        //         this.$router.push('/');
        //     });
        // }
        if (!this.$store.state.pickup.locationFrom) {
            this.$router.push('/pickup/selectlocation');
        } else {
            this.$store.dispatch('pickup/validate');
        }
    },
    mounted() {
        this.pojazd_id = this.$store.state.pojazd_id;
        this.location_id = this.$store.state.location_id;
        //  this.orders = this.$store.state.orders;
        this.loadTowary = this.$store.state.loadTowary;
        // this.calculateInStock();
        // this.countIlosc()
    }
}
</script>

