export default {
    store: {},
    valid: true,
    errors: [],
    warnings: [],
    validateQuantity: function (state, getters, rootGetters) {
        let products = state.products;
        if (products.length == 0) {
            this.pushProductError({ product_id: 0 }, 'noProduct');
        }
        let valid = true;
        products.forEach(item => {
            let qty = rootGetters['storage/countEmptyOnStorage'](item.product_id);
            if (qty < item.quantityEmpty && qty > 0) {
                this.pushProductError(item, 'noEnoughProductLocation', 'danger');
            }

        })

        let badCylinder = getters.getBadCylinder;
        console.log('badCylinder', badCylinder)
        badCylinder.forEach(cylinder => {
            this.pushCylinderError(cylinder, 'badCylinderProduct', 'danger');
        })

        return valid;
    },

    validateCylinder: function (state, getters, rootState) {
        state.cylinders.forEach(cylinder => {
            let product = getters.getProduct(cylinder.product_id);
            console.log('s', product);
            if (product) {
                if (cylinder.cylinder_status == 'full') {
                    this.pushCylinderError(cylinder, 'full', 'warning');
                }


            } //else {
            //     this.pushCylinderError(cylinder, 'orderFail');
            // }
            if (cylinder.isComplaint == true) {
                this.pushCylinderError(cylinder, 'complaint');
            }
            if (cylinder.isLegalization == false) {
                this.pushCylinderError(cylinder, 'legalization', 'danger');
            }
            if (cylinder.location_id != rootState.location_id) {
                this.pushCylinderError(cylinder, 'locationFail', 'warning');
            }

        });


    },
    pushProductError: function (product, type, level = 'error') {
        let obj = { cylinder_id: 0, product_id: product.product_id, type: type, info: '' };
        switch (type) {
            case 'quantity':
                obj.info = 'Stan ' + (product.title ? product.title : product.name) + ' jest niezgodny';
                break;
            case 'emptyCylinder':
                obj.info = 'Produkt ' + (product.title ? product.title : product.name) + ' zawiera puste butle';
                break;
            case 'noEnoughProductCar':
                obj.info = 'Produkt ' + (product.title ? product.title : product.name) + ' nie ma tyle na aucie';
                break;
            case 'noEnoughProductLocation':
                obj.info = 'Produkt ' + (product.title ? product.title : product.name) + ' nie ma tyle na magazynie';
                break;
            case 'noProduct':
                obj.info = 'Musisz dodać jakieś produkty żeby je wydać.';
                break;
        }
        if (level == 'warning') {
            this.warnings.push(obj)
        }
        else {
            this.errors.push(obj)
        }
    },
    pushCylinderError: function (cylinder, type, level = 'error') {
        let obj = { cylinder_id: cylinder.cylinder_id, product_id: cylinder.product_id, type: type, info: '' };
        switch (type) {
            case 'empty':
                obj.info = 'Butla #' + cylinder.cylinder_qr_code + ' z ' + cylinder.cylinder_name + ' jest pusta';
                break;
            case 'full':
                obj.info = 'Butla #' + cylinder.cylinder_qr_code + ' z ' + cylinder.cylinder_name + ' jest pełna';
                break;
            case 'complaint':
                obj.info = 'Butla #' + cylinder.cylinder_qr_code + ' z ' + cylinder.cylinder_name + ' ma reklamacje';
                break;
            case 'legalisation':
                obj.info = 'Butla #' + cylinder.cylinder_qr_code + ' z ' + cylinder.cylinder_name + ' ma zbliżającą się legalizacje';
                break;
            case 'orderFail':
                obj.info = 'Butla #' + cylinder.cylinder_qr_code + ' z ' + cylinder.cylinder_name + ' nie pasuje do zamówień';
                break;
            case 'locationFail':
                obj.info = 'Butla #' + cylinder.cylinder_qr_code + ' z ' + cylinder.cylinder_name + ' nie znajduje się w tym magazynie';
                break;
            case 'badCylinderProduct':
                obj.info = 'Butla #' + cylinder.cylinder_qr_code + ' z ' + cylinder.cylinder_name + ' nie pasuje do żadnego produktu';
                break;
            case 'legalization':
                obj.info = 'Butla #' + cylinder.cylinder_qr_code + ' z ' + cylinder.cylinder_name + ' jest do legalizacji [' + cylinder.cylinder_legalization_date + '] !';
                break;
        }
        if (level == 'warning') {
            this.warnings.push(obj)
        }
        else {
            this.errors.push(obj)
        }
    }
}