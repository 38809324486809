<template>
    <div class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative">


        <div class="w-100 d-flex justify-content-between align-items-center" style="background: #dadada">
            <router-link to="/" class="btn btn-lg btn-secondary m-2"><i class="bi bi-house"></i></router-link>
            <h2 class="flex-fill m-0">Podgląd butli {{ cylinder.cylinder_qr_code }}</h2>
        </div>



        <div v-if="!notFound" class="w-100 p-2 pb-5" style="overflow: auto; flex-grow: 2;">

            <CylinderInfo :cylinder="cylinder"></CylinderInfo>
            <h3>Ostanie logi</h3>
            <CylinderLogs :cylinder_id="cylinder.cylinder_id" limit="5"></CylinderLogs>
        </div>
        <div v-if="notFound" class="w-100 p-2 pb-5 text-center mt-5" style="overflow: auto; flex-grow: 2;">
            <div>
                <i class="fs-1 text-danger bi bi-exclamation-diamond-fill"></i>
            </div>
            <h2>Butla o kodzie {{ $route.params.code }} nie została znaleziona</h2>

            <router-link to="/cylinder/search" class="btn btn-outline-secondary mt-4">Wróć</router-link>
        </div>
        <KeyboardScanner :listen="true" @input="searchCylinder"></KeyboardScanner>


    </div>

</template>

<script>
import CylinderInfo from '@/components/CylinderInfo.vue';
import CylinderLogs from '@/components/CylinderLogs.vue';
import KeyboardScanner from '@/components/KeyboardScanner.vue';

export default {
    name: "CylinderSearch",
    data: function () {
        return {
            pin: "",
            cylinder: {},
            notFound: false,
        };
    },
    methods: {
        modalOk: function () {
        },

        searchCylinder: function (code) {
            this.$router.push('/cylinder/qrcode/' + code);
        }
    },
    mounted: function () {
        this.$store.dispatch('storage/findCylinder', this.$route.params.code).then(cylinder => {
            this.cylinder = cylinder;
        }, () => {
            this.notFound = true;
        })
    },
    components: { CylinderInfo, CylinderLogs, KeyboardScanner }
}
</script>