<template>
    <div>

        <div v-if="cylinder._offline" class="alert bg-danger w-100">
            Informacje z wersji offline
        </div>
        <table v-if="cylinder._offline" class="table text-start">
            <tbody>
                <tr>
                    <th scope="row" class="text-start">Towar:</th>
                    <td> {{ cylinder.cylinder_name }}</td>
                </tr>
                <tr>
                    <th scope="row" class="text-start">Status:</th>
                    <td> {{ cylinder.isEmpty ? 'Pusta' : 'Pełna' }}</td>
                </tr>
            </tbody>
        </table>

        <table v-if="!cylinder._offline" class="table text-start">
            <tbody>
                <tr>
                    <th scope="row" class="text-start">Towar:</th>
                    <td> {{ cylinder.cylinder_name }} <router-link v-show="$store.getters['storage/isInStorage'](cylinder.product_id)" class="ms-1 fs-5" :to="'/storage/product/'+cylinder.product_id"><i class="bi bi-box-arrow-up-right"></i></router-link></td>
                </tr>
                <tr>
                    <th scope="row" class="text-start">Data legalizacji:</th>
                    <td :class="cylinder.isLegalization==false?'text-danger':''"> {{ cylinder.cylinder_legalization_date }}</td>
                </tr>
                <tr>
                    <th scope="row" class="text-start">Producent butli:</th>
                    <td> {{ cylinder.cylinder_producent }}</td>
                </tr>
                <tr>
                    <th scope="row" class="text-start">Głowica:</th>
                    <td> {{ cylinder.cylinder_head == '1' ? 'TAK' : 'NIE' }}</td>
                </tr>
                <tr>
                    <th scope="row" class="text-start">Rurka wgłebna:</th>
                    <td> {{ cylinder.cylinder_pipe == '1' ? 'TAK' : 'NIE' }}</td>
                </tr>
                <tr>
                    <th scope="row" class="text-start">Numer producenta:</th>
                    <td> {{ cylinder.cylinder_serial_number }}</td>
                </tr>
                <tr>
                    <th scope="row" class="text-start">Pojemność [l]:</th>
                    <td> {{ Math.round(cylinder.cylinder_capacity / 1000) }}</td>
                </tr>
                <tr>
                    <th scope="row" class="text-start">Waga [kg]:</th>
                    <td> {{ Math.round(cylinder.cylinder_weight / 1000) }}</td>
                </tr>
                <tr>
                    <th scope="row" class="text-start">Gaz:</th>
                    <td> {{ cylinder.cylinder_gas_type }}</td>
                </tr>
                <tr v-if="cylinder.location">
                    <th scope="row" class="text-start">Aktualna lokalizacja:</th>
                    <td> {{ cylinder.location.location_name }}</td>
                </tr>
                <tr>
                    <th scope="row" class="text-start">Status:</th>
                    <td> {{ cylinder.isEmpty ? 'Pusta' : 'Pełna' }}</td>
                </tr>
                <tr :class="cylinder.isComplaint?'text-danger':''">
                    <th scope="row" class="text-start">Reklamacja:</th>
                    <td> {{ cylinder.isComplaint ? 'TAK' : 'NIE' }}</td>
                </tr>
            </tbody>
        </table>
        <div v-if="cylinder.isComplaint">
            <h2>Reklamacje</h2>
            <CylinderComplaintInfo :cylinder_id="cylinder.cylinder_id"></CylinderComplaintInfo>
        </div>
    </div>

</template>
<script>
import CylinderComplaintInfo from './CylinderComplaintInfo.vue';

export default {
    name: "CylinderInfo",
    props: {
        cylinder: Object
    },
    data: function () {
        return {};
    },
    mounted() {
    },
    components: { CylinderComplaintInfo }
}
</script>