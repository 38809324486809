<template>
    <div class="h-100 w-100 d-flex flex-column align-items-center justify-content-between position-relative">
        <div class="w-100 d-flex justify-content-end align-items-center" style="background: #0d6efd; color: #fff;">
            <router-link to="/" class="btn btn-lg btn-primary m-2"><i class="bi bi-x-lg"></i></router-link>
        </div>

        <div class="w-100 p-2 pb-5" style="overflow: auto; flex-grow: 2;">


            <div class="navbar bg-light mb-2">
                <div class="container-fluid">
                    <a @click.prevent="logout" class="navbar-brand" href="#">Wyloguj się</a>
                </div>
            </div>
            <div class="navbar bg-light mb-2">
                <div class="container-fluid">
                    <router-link to="/settings" class="navbar-brand" href="#">Ustawienia</router-link>
                </div>
            </div>
            <p class="my-4">Data kompilacji: {{ buildDate }}</p>

        </div>
        <!-- <button @click="fix">fix</button> -->
        <div class="navbar mb-2 w-100">
            <div class="container-fluid">
                <span class="navbar-brand" href="#">Twój magazyn: {{ $store.state.location_name }}</span>
            </div>
        </div>
</div>
</template>
<script>
import dayjs from 'dayjs'
export default {
    name: 'AppMenu',
    props: {},
    data: function () { return {} },
    computed: {
        buildDate: function () {
            return dayjs(document.documentElement.dataset.buildTimestampUtc).format('YYYY-MM-DD HH:mm:ss')
        }
    },
    methods: {
        logout: function () {
            this.$swal({
                title: 'Czy napewno?',
                text: "Ta akcja wyczyści wszystko co zeskanowałeś do tej pory! Jest to nieodrwacalne",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Wróć',
                confirmButtonText: 'Wyloguj!'
            }).then(response => {
                if (response.isConfirmed) {
                    this.$store.dispatch('clearData');
                    this.$store.commit('logout');
                    this.$router.push('/');
                }
            })
        },
        fix: function () {
            this.$store.commit('storage/clearOrderQuene');
            alert('success');
        }
    },
    mounted() { },
}
</script>