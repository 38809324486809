import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persist-indexeddb";
import api from '../api/myApi';
import Pickup from "./pickup.js";
import Zaladunek from "./zaladunek.js";
import disloading from "./disloading.js";
import Car from "./storage.js";
import Complaint from "./complaint.js";
import Production from "./production.js";

Vue.use(Vuex)
const t = createPersistedState({
    'key': 'vuexblueapp',
    'fetchBeforeUse': true
})
export default new Vuex.Store({
    plugins: [t],
    namespaced: true,
    modules: { pickup: Pickup, zaladunek: Zaladunek, disloading: disloading, storage: Car, complaint: Complaint, production: Production },
    state: {
        kartaDrogowa: null,
        user_id: 0,
        kierowca: {},
        pojazd_id: 0,
        location_id: 0,
        location_name: '',
        timeout: 0,
        config: [],
        loading: true,
        pickup: {},
        zaladunek: {},
        disloading: {},
        car: {},
        hasInternet: true,
    },
    mutations: {
        //zamowienai
        order(state, data) {
            state.order = data;
        },
        setUserId(state, data) {
            state.user_id = data;
        },
        setKierowca(state, data) {
            state.kierowca = data;
        },
        setHasInternet(state, data) {
            state.hasInternet = data;
        },
        addSignature(state, data) {
            state.order.signature = data;
        },
        setorder(state, data) {
            console.log('state.order', data)
            state.order = data
        },
        resetOrder(state) {
            console.log('state.order', null)
            state.order = null
        },
        getters() {
            return this.state.orders;
        },
        initialiseStore() {
            this.commit('setLoading', true)
            console.log('init', this)
        },
        changeLocation(state, data) {
            state.location_name = data.location_name,
                state.location_id = data.location_id;
        },
        setKartaDrogowa(state, data) {
            state.kartaDrogowa = data
        },
        setLoading(state, data) {
            state.loading = data;
        },

        setPojazdId: function (state, data) {
            state.pojazd_id = data;
        },
        logout: function (state) {
            state.user_id = null;
            state.kartaDrogowa = null;
        },
        setLocationName: function (state, data) {
            state.location_name = data;
        },
        setLocationId: function (state, data) {
            console.log('state.location', data)
            state.location_id = data;
        },

        increaseTimeout: function (state, time = 60) {
            state.timeout += time;
        },
        clearTimeout: function (state) {
            state.timeout = 0;
        },
        setConfig: function (state, config) {
            state.config = config;
        },
        setOrders: function (state, data) {
            state.orders = data;
            console.log('set orders', data)
            // this.$store.commit('greenap.orders', JSON.stringify(state.orders));
        },
        setCylinders: function (state, data) {
            state.cylinders = data;
            // this.$store.commit('greenap.cylinders', JSON.stringify(state.cylinders));
        },
        setLoadTowary: function (state, data) {
            state.loadTowary = data;
            // this.$store.commit('greenap.loadTowary', JSON.stringify(state.loadTowary));
        },

    },
    getters: {
        getTowarById: (state) => (id) => {


            return state.todos.find(todo => todo.id === id)
        }
    },
    actions: {

        async cylinderFind(vm, qrcode) {
            console.log('znajdz cylinder', qrcode);
            return await api.findCylinder(qrcode);
        },
        clearData: function ({ dispatch }) {
            dispatch('zaladunek/reset')
            dispatch('pickup/reset')
            dispatch('disloading/reset')
        },
        checkKartaStillOpen({ state }) {
            return new Promise((resolve, reject) => {
                api.getKartaDrogowa(state.kierowca_id).then(response => {
                    if (state.location_id == response.data.location_id) {
                        resolve();
                    } else {
                        reject();
                    }
                }, () => {
                    reject();
                })
            });
        },
        orderSummary(vm) {
            var data = {
                'commment': vm.state.order.comment,
                'signature': vm.state.order.signature,
                'order_id': vm.state.order.id,
            }
            return api.patchOrder(data).then((e) => {
                console.log(e)
            })
        },
        // changeLocation(vm, location) {
        //     vm.commit('setPojazdId', location.magazyn_id);
        //     vm.commit('setLocationId', location.location_id);
        //     vm.commit('setLocationName', location.location_name);
        //     vm.dispatch('getOrders'); //po zmianie lokacji rpzeładowujemy zamówienia
        //     console.log('po zmianie lokacji rpzeładowujemy zamówienia');
        // },
        getKartaDrogowa(vm) {
            console.log('vm', this._vm);
            api.getKartaDrogowa(vm.state.kierowca_id).then(response => {
                vm.commit('setLocationId', response.data.id_pojazdu);
                vm.commit('setLocationId', response.data.location_id);
                vm.commit('setLocationName', response.data.location_name);
                vm.commit('setKartaDrogowa', response.data);
                console.log('karta drogowa', response.data);
            }, e => {
                vm.commit('setKartaDrogowa', null);
                console.log('orders', e)
            });
        },

    },

})